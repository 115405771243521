import idventure from "../../images/brands/idventure.svg";

import hiddengames from "../../images/brands/hiddengames.svg";
import stor from "../../images/brands/stor.svg";
import woodtrick from "../../images/brands/woodtrick.svg";
import odoro from "../../images/brands/odoro.svg";
import culinariomortale from "../../images/brands/culinariomortale.svg";
import siebenstein from "../../images/brands/siebenstein.svg";
import constantin from "../../images/brands/constantin.svg";
import tinycircuits from "../../images/brands/tinycircuits.svg";
import circuitMess from "../../images/brands/CircuitMess.svg";
import quokka from "../../images/brands/quokka.svg";
import enjoythewood from "../../images/brands/enjoythewood.svg";
import hardicraft from "../../images/brands/hardicraft.svg";
import mamatoyz from "../../images/brands/mamaToyz.svg";
import brickbling from "../../images/brands/brickbling.svg";
import superclub from "../../images/brands/super_club.svg";
import kendama from "../../images/brands/kendama.svg";
import escapewelt from "../../images/brands/escapewelt.svg";
import safta from "../../images/brands/safta.svg";
import gourmetCriminelle from "../../images/brands/gourmet-criminelle.svg";
import unidragon from "../../images/brands/unidragon.svg";
import egan from "../../images/brands/egan.svg";
import crimecases from "../../images/brands/crimecases.svg";
import goofi from "../../images/brands/goofi.svg";
import inscape from "../../images/brands/inscape.svg";
import numskull from "../../images/brands/numskull1.svg";
import cartonic from "../../images/brands/cartonic.svg";
import tossit from "../../images/brands/tossit_logo.svg";
import puzzlepotato from "../../images/brands/puzzle potato.svg";
import bily from "../../images/brands/bily.svg";
import agnawool from "../../images/brands/agna_wool.svg";
import inkari from "../../images/brands/inkari.svg";
import minix from "../../images/brands/minix.svg";
import oniverse from "../../images/brands/oniverse.svg";
import remotto from "../../images/brands/remotto.svg";
import crazybastard from "../../images/brands/crazybastard.svg";
import elastikorps from "../../images/brands/elastikorps.svg";
import keebox from "../../images/brands/keebox.svg";
import chigee from "../../images/brands/chigee.svg";
import bryx from "../../images/brands/bryx.svg";

export const BrandsList = [
  {
    name: "iDventure",
    desc: "iDventure Lorem Ipsum lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: idventure,
    bg: "#63d48c",
    category: "Spiele",
    isOpen: true,
    vertical: false,
  },
  {
    name: "Escape Welt",
    desc: "Escape Welt lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: escapewelt,
    bg: "#63d48c",
    vertical: true,
    category: "Knobelsüiele",
  },
  {
    name: "Stor",
    desc: "Genesis lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: stor,
    bg: "#63d48c",
    category: "Lizenzartikel",
    vertical: true,
  },
  {
    name: "Enjoy The Wood",
    desc: "Enjoy The Wood lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: enjoythewood,
    bg: "#63d48c",
    category: "Weltkarten",
    vertical: true,
  },
  {
    name: "Siebenstein Spiele",
    desc: "Genesis lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: siebenstein,
    bg: "#63d48c",
    category: "Spiele",
    vertical: true,
  },
  {
    name: "Crime Cases",
    desc: "crime cases Lorem Ipsum lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: crimecases,
    bg: "#7ab0eb",
    category: "Spiele",
    vertical: true,
  },
  {
    name: "Tossit",
    desc: "crime cases Lorem Ipsum lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: tossit,
    bg: "#7ab0eb",
    category: "Spiele",
  },
  {
    name: "Superclub",
    desc: "super club Lorem Ipsum lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: superclub,
    bg: "#7ab0eb",
    category: "Spiele",
    vertical: true,
  },
  {
    name: "Jean-Claude Constantin",
    desc: "Genesis lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: constantin,
    bg: "#63d48c",
    category: "Spiele",
    vertical: true,
  },
  {
    name: "WoodTrick",
    desc: "Genesis lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: woodtrick,
    bg: "#63d48c",
    category: "Spiele",
  },
  {
    name: "Crazy Bastards Sauce",
    desc: "Genesis lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: crazybastard,
    bg: "#63d48c",
    category: "Saucen",
    vertical: true,
  },
  {
    name: "BRYX",
    desc: "BRYX lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: bryx,
    bg: "#63d48c",
    category: "Spiele",
    vertical: true,
  },
  {
    name: "Gourmet Criminelle",
    desc: "Gourmet Criminelle lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: gourmetCriminelle,
    bg: "#63d48c",
    vertical: true,
    category: "Spiele",
  },
  {
    name: "kendama",
    desc: "kendama lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: kendama,
    bg: "#63d48c",
    vertical: true,
    category: "Spiele",
  },
  {
    name: "Goofi",
    desc: "Goofi Lorem Ipsum lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: goofi,
    bg: "#7ab0eb",
    category: "Figuren",
  },
  {
    name: "BrickBling",
    desc: "super club Lorem Ipsum lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: brickbling,
    bg: "#7ab0eb",
    category: "Spiele",
    vertical: true,
  },
  {
    name: "Bily",
    desc: "Bily lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: bily,
    bg: "#63d48c",
    category: "Lampe",
  },
  {
    name: "mamatoyz",
    desc: "mamatoyz lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: mamatoyz,
    bg: "#63d48c",
    category: "Babyprodukte",
    vertical: true,
  },
  {
    name: "CircuitMess",
    desc: "CircuitMess lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: circuitMess,
    bg: "#63d48c",
    category: "Spiele",
  },
  {
    name: "Puzzle Potato",
    desc: "CircuitMess lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: puzzlepotato,
    bg: "#63d48c",
    category: "Spiele",
  },
  {
    name: "Culinario Mortale",
    desc: "Genesis lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: culinariomortale,
    bg: "#63d48c",
    category: "Krimidinner",
    vertical: true,
  },
  {
    name: "Hardicraft",
    desc: "Hardicraft lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: hardicraft,
    bg: "#63d48c",
    category: "Häkelsets",
  },
  {
    name: "Agna Wool",
    desc: "Agna Wool lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: agnawool,
    bg: "#63d48c",
    category: "Basteln",
    vertical: true,
  },
  {
    name: "Chigee",
    desc: "Chigee lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: chigee,
    bg: "#63d48c",
    category: "Motorrad",
  },
  {
    name: "Unidragon",
    desc: "Unidragon Lorem Ipsum lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: unidragon,
    bg: "#f58576",
    category: "geschirr",
    vertical: false,
  },
  {
    name: "Egan",
    desc: "Egan Lorem Ipsum lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: egan,
    bg: "#f58576",
    category: "puzzles",
    vertical: false,
  },
  {
    name: "Safta",
    desc: "Safta lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: safta,
    bg: "#63d48c",
    category: "Schule",
  },
  {
    name: "Hidden Games",
    desc: "Hidden Lorem Ipsum lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: hiddengames,
    bg: "#f58576",
    category: "Spiele",
    vertical: true,
  },
  {
    name: "keebox",
    desc: "keebox Lorem Ipsum lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: keebox,
    bg: "#f58576",
    category: "Spiele",
  },
  {
    name: "Oniverse",
    desc: "Oniverse lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: oniverse,
    bg: "#63d48c",
    category: "Gaming",
    vertical: true,
  },
  {
    name: "Odoro Fragrances",
    desc: "Genesis lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: odoro,
    bg: "#63d48c",
    category: "Haushalt",
    vertical: true,
  },
  {
    name: "Inkari",
    desc: "Inkari Lorem Ipsum lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: inkari,
    bg: "#7ab0eb",
    category: "Plüschtiere",
    vertical: true,
  },

  {
    name: "Quokka",
    desc: "quokka Lorem Ipsum lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: quokka,
    bg: "#7ab0eb",
    category: "Flaschen",
  },
  {
    name: "Minix",
    desc: "Minix Lorem Ipsum lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: minix,
    bg: "#7ab0eb",
    category: "Sammelfiguren",
  },
  {
    name: "Inscape",
    desc: "Inscape Lorem Ipsum lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: inscape,
    bg: "#7ab0eb",
    category: "Spiele",
    vertical: true,
  },
  {
    name: "Tiny Citcuits",
    desc: "Genesis lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: tinycircuits,
    bg: "#63d48c",
    category: "Elektronik",
    vertical: true,
  },
  {
    name: "elatikorps",
    desc: "elastikorps lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: elastikorps,
    bg: "#63d48c",
    vertical: true,
    category: "Spiele",
  },
  {
    name: "Remotto",
    desc: "Remotto lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: remotto,
    bg: "#63d48c",
    category: "Gaming",
  },
  {
    name: "numskull",
    desc: "numskull lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: numskull,
    bg: "#63d48c",
    category: "Lizenzartikel",
  },
  {
    name: "Cartonic",
    desc: "Cartonic Lorem Ipsum lala lepi lu nima watashi wa gemu krimi honda sashimi yokohama.",
    logo: cartonic,
    bg: "#7ab0eb",
    category: "Puzzle",
  },
];
